import { Component, OnInit } from "@angular/core";
import { forkJoin, map, Observable, switchMap, timer } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Constants } from "../../../constants/constants";
import { SourcesService, Pid } from "../../sources/sources.service";
import { Source } from "../../../models/shared";
import * as _ from "lodash";

@Component({
    selector: "app-shared-source-details",
    templateUrl: "./shared-source-details.component.html",
    styleUrls: ["./shared-source-details.component.scss"]
})
export class SharedSourceDetailsComponent implements OnInit {
    data$: Observable<{ source: Source; pids: Pid[] }>;
    isThumbnailOverlayShow = false;

    constructor(private sourcesService: SourcesService, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        const sourceId = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
        const refreshTimer$ = timer(0, Constants.MINUTE);
        this.data$ = refreshTimer$.pipe(
            switchMap(() =>
                forkJoin({
                    source: this.sourcesService.refreshSource(sourceId, true),
                    pids: this.sourcesService.getSourceBitrates(sourceId) ?? []
                }).pipe(
                    map(({ source, pids }) => {
                        return {
                            source,
                            pids: pids ?? []
                        };
                    })
                )
            )
        );
    }
}
