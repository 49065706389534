<div class="login-container">
    <div data-zm="login-logo" zmid="login-logo" class="row">
        <div class="col text-center">
            <img width="250" alt="ZEN Master logo" class="align-middle" src="../../../assets/images/ZEN-Master-Logo-white.png" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'width.px': 320 }">
                <div class="card-body">
                    <form (ngSubmit)="onSubmit()">
                        <h3>{{ "SIGN_IN" | translate }}</h3>
                        <app-input-generic-field
                            type="password"
                            autoComplete="cc-csc"
                            [formControl]="form.controls.password"
                            [textFieldName]="'PASSWORD' | translate"
                            [isParentFormSubmitted]="isFormSubmitted"
                        ></app-input-generic-field>
                        <button class="mt-3 btn btn-primary" [disabled]="isLoading">
                            {{ "LOGIN" | translate }}
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </form>
                </div>
            </div>
            <app-launch-page-footer></app-launch-page-footer>
        </div>
    </div>
</div>
