// Modules
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { FormsModule } from "@angular/forms";

// Routing
import { SharedPagesRouting } from "./shared-pages.routing";
// Components
import { SharedLoginComponent } from "./login/shared-login.component";
import { SharedSourceDetailsComponent } from "./source-details/shared-source-details.component";
@NgModule({
    imports: [SharedPagesRouting, CommonModule, NgbModule, FontAwesome, SharedModule, FormsModule],
    declarations: [SharedLoginComponent, SharedSourceDetailsComponent]
})
export class SharedPagesModule {}
