import { Injectable } from "@angular/core";
import {
    CanActivate,
    CanLoad,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Route,
    UrlSegment,
    Router
} from "@angular/router";
import { Constants } from "./../constants/constants";
import { SharedLoginService } from "../pages/shared/login/shared-login.service";

@Injectable({
    providedIn: "root"
})
export class SharedPagesAuthGuard implements CanActivate, CanLoad {
    constructor(private sharedLoginService: SharedLoginService, private router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isSharedLoginPage = route.routeConfig.path === Constants.urls.shared.login;
        if (isSharedLoginPage || this.sharedLoginService.isLoggedIn) {
            return true;
        }
        this.sharedLoginService.init(window.location.href);
        return this.router.parseUrl(Constants.urls.shared.login);
    }

    canLoad(route: Route, segments: UrlSegment[]) {
        const isValidSharedRoute = segments.length > 1;
        return isValidSharedRoute || this.router.parseUrl(Constants.urls.login);
    }
}
