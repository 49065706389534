import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedPagesAuthGuard } from "src/app/guards/shared-pages-auth.guard";

import { Constants } from "../../constants/constants";
import { SharedLoginComponent } from "./login/shared-login.component";
import { SharedSourceDetailsComponent } from "./source-details/shared-source-details.component";

const sharedPagesRoutes: Routes = [
    {
        path: Constants.urls.shared.login,
        component: SharedLoginComponent,
        canActivate: [SharedPagesAuthGuard]
    },
    {
        path: Constants.urls.shared.sources + "/:id",
        component: SharedSourceDetailsComponent,
        canActivate: [SharedPagesAuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(sharedPagesRoutes)],
    exports: [RouterModule]
})
export class SharedPagesRouting {}
